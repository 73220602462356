import { ISPListItem } from '../interfaces/ISPListItem';
import { IHttpClient, IHttpClientResponse } from './IHttpClient';
import { Filter } from '../models/FilterModel';
import { HashMap } from '../interfaces/IHashMap';
import { ISPField } from '../interfaces/ISPField';

export interface ISPListService<I extends ISPListItem> {
  getItems(filter?: Filter): Promise<I[]>;  
}

export class SPListService<I extends ISPListItem> implements ISPListService<I> {
  
  private _webUrl :string|undefined;
  private _properties:string[];
  //  protected _odataParameters = "$select=";


  constructor(protected spClient :IHttpClient, protected listName:string, protected spDataType:string, protected fields:ISPField[]) {    
    console.log("SPListService.constructor");
    const siteUrl = process.env.REACT_APP_FRONTEND_SHAREPOINT_URL!+process.env.REACT_APP_FRONTEND_SHAREPOINT_SITE_URL!;
    if (!siteUrl) throw new Error("No site provided");
      
    this._properties =["Title,Id"];          // TODO : faire le tableau de propriété
    fields.forEach((field)=>{
      this._properties.push(field.internalName);
    });
    this._webUrl = siteUrl;
    console.log(this._webUrl);
  }


  private getClearType(obj:I){
    let clearType :any={};   
    var casted_object: HashMap<I> = obj as HashMap<any>;
    this.fields.forEach((field)=>{
        if(field.internalName.indexOf("/")>0){            
            if (!clearType[field.internalName.split("/")[0]]){
                clearType[field.internalName.split("/")[0]]={};
            }
            //console.log(field.internalName);
            // if(this[field.internalName.split("/")[0]]){
            //     clearType[key.split("/")[0]][key.split("/")[1]] = obj[key.split("/")[0]][key.split("/")[1]];
            // }
        }
        else{
          clearType[field.internalName]= casted_object[field.internalName];                
        }        
    });
    return clearType as I;
}


private loop_over_props(object: I) {
  var casted_object: HashMap<any> = object as HashMap<any>;
  for (let k in casted_object)
    console.log("OOO "+ k +":"+ casted_object[k]);
}


  public  getItems(filter?: Filter): Promise<I[]> {    
    console.info("SPListService.getItems");
    
    let queryFilter = "";
    if (filter) {
      queryFilter = "&" + filter.getQueryFilters();
    }
    
    const queryString = `/_api/web/lists/getbytitle('${this.listName}')/items?${queryFilter}`;
 
    let options = {
      headers: {
        'Accept': 'application/json;odata=verbose',
        'odata-version': ''
      }
    }

    return this.spClient.get(queryString,options)
      .then((response: IHttpClientResponse) => {
        return response.json().then(
          (jsonResponse: { d: { results: string | any[]; }; }) => {
            if ( jsonResponse.d.results.length > 0) {              
              let items: any = jsonResponse.d.results;                 
               let itemsToReturn: I[] = [];
               items.forEach((item:I) => {                  
                itemsToReturn.push(this.getClearType(item));                               
              });
              return itemsToReturn as I[];
            }
            else
              return [];
          }
        );
      });
  }

  

  // CRUD

  private communCreateUpdate(item: I,postHeader:{}, customBody?:{}){

    let postBody: {[k: string]: any} = {};
    if (customBody){
      postBody=customBody;
    }
    else{ 

      postBody['__metadata']= { 'type': this.spDataType };
      postBody['Title'] = `${item}`;
      this._properties.forEach((prop)=>{
        
        if(prop.toLowerCase()!=="id"){
          if (prop.indexOf("/")>0)
          {
            //TODO extended field
            // if(item[prop.split("/")[0]]){
            //   if(prop.split("/")[1].toLowerCase()=="id"){
            //     //internal lookup fiedName = <FieldName>Id 
            //     const fieldName =prop.split("/")[0]+prop.split("/")[1];
            //     body[fieldName]=item[prop.split("/")[0]][prop.split("/")[1]];
            //   }
            // }
            ///TODO: multiple lookupfield        
          }        
          else{
            postBody[prop]= item[prop];
            // TODO: ajouter test cas particulier (people, taxonomy)
            // ex si props de type user => body[prop]= item[prop].Id;
          }
        }         
      });
    }  


    let options = {
      headers:postHeader,
      body:JSON.stringify(postBody)
    }

    return options;
  }

  public Create(item: I, customBody?:{}): Promise<boolean> {
    console.log("SPListService.Create");
    
    const header= {
      'Accept': 'application/json;odata=nometadata',
      'Content-type': 'application/json;odata=verbose',
      'odata-version': ''
    };
    const options = this.communCreateUpdate(item,header,customBody);

    const queryString =`/_api/web/lists/getbytitle('${this.listName}')/items`;
    return this.spClient.post(queryString,options).then((response: { ok: any; })=>{
      return response.ok;
    });
  }
  
  public Read(itemId: number): Promise<I> {
    console.log("SPListService.read");
    
    let options = {
      headers: {
        'Accept': 'application/json;odata=verbose',
        'odata-version': ''
      }
    }

    //const odataParameters = this.getODataParameters();
    const odataParameters="";
    let queryUrl =`/_api/web/lists/getbytitle('${this.listName}')/items(${itemId})?${odataParameters}`;
   
    return this.spClient.get(queryUrl,options).then((response: { json: () => Promise<I>; })=>{
      return response.json().then((jsonResponse: I)=>{return this.getClearType(jsonResponse)});
    });
  }

  public Update(item: I, customBody?:{}): Promise<boolean> {
    console.log("SPListService.Create");
    
    const header = {
      'Accept': 'application/json;odata=nometadata',
      'Content-type': 'application/json;odata=verbose',
      'odata-version': '',
      'IF-MATCH': '*',
      'X-HTTP-Method': 'MERGE'
    }
    const options = this.communCreateUpdate(item,header, customBody);
    
    const queryString =`/_api/web/lists/getbytitle('${this.listName}')/items(${item.Id})`;
    return this.spClient.post(queryString,options).then((response: { ok: any; })=>{
      return response.ok;
    });
  }

  public Delete(id: number): Promise<boolean> {
    console.log("SPListService.delete");
    
    if (isNaN(id))
    {  
      Promise.reject("Id is not a number");
      return Promise.resolve(false);

    }
    else{
      const queryString =`/_api/web/lists/getbytitle('${this.listName}')/items(${id})/recycle()`;

      let options = {
        headers: {
          "ACCEPT": "application/json;odata:minimal",
          'Content-type': 'application/json;odata=verbose',
          'IF-MATCH': '*',
          'X-HTTP-Method': 'DELETE',
          'odata-version': ''
        }
      };
    
      return this.spClient.post(queryString,options).then((response: { ok: any; })=>{
        return response.ok;
      });
  
    }
  }  

}
