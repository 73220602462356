import { IHttpClient } from "../services/IHttpClient";
import { ICar } from "../interfaces/ICars";
import { HashMap } from "../interfaces/IHashMap";
import { ISPField } from "../interfaces/ISPField";
import { SPListService } from "../services/SPListService";
import { ISPListItem } from "../interfaces/ISPListItem";

export class Car implements ICar{

    [key: string]: any;
    Id!: number;
    Title!: string;
    public Gear!:number;
    public Started!:boolean;        

    private static listName ="cars";
    public static DataType = "SP.Data.CarsListItem";
    public static Fields:ISPField[]=[
            {internalName:"Id",fieldType:"number"},
            {internalName:"Title",fieldType:"string"},           
            {internalName:"Gear",fieldType:"number"},
            {internalName:"Started",fieldType:"boolean"}
        ];
    

    public static Cast(item:ICar):Car{
         
        let newItem = new Car();
        var casted_object: HashMap<any> = item as HashMap<any>;
        for (let k in casted_object)
        newItem[k]= casted_object[k];
        return newItem;
    }

    public static GetCars(spClient:IHttpClient):Promise<Car[]>{
        
        let service = new SPListService<ICar>(spClient!,Car.listName,Car.DataType,Car.Fields);
        let cars :Car[]=[];
        return  service.getItems().then((results: ICar[])=>{ 
            results.forEach((c:ICar)=> {cars.push(Car.Cast(c));});
            return cars;
        });
    };    
    
    public Create(spClient: IHttpClient): Promise<boolean> {
        let service = new SPListService<ICar>(spClient!,Car.listName,Car.DataType,Car.Fields);
        return service.Create(this);                      
    }


    //public Read(spClient: IHttpClient, id:number): Promise<Car> {
    //    let service = new SPListService<ICar>(spClient!,Car.listName,Car.DataType,Car.Fields);
    //    return service.Read(id).then((response)=>{return new Car(response);});   
    //}
    static Read(spClient: IHttpClient): Promise<ISPListItem> {
        throw new Error("Method not implemented.");
    }

    public Update(spClient: IHttpClient): Promise<boolean> {
        let service = new SPListService<ICar>(spClient!,Car.listName,Car.DataType,Car.Fields);
        return service.Update(this);   
    }
    public Delete(spClient: IHttpClient): Promise<boolean> {
        let service = new SPListService<ICar>(spClient!,Car.listName,Car.DataType,Car.Fields);
        return service.Delete(this.Id);   
        
    }
    
}


